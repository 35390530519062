import React, { forwardRef, RefObject, useState } from 'react';
import c from 'classnames';
import Control, { ControlProps } from '../Control/Control';
import { Input, InputProps, TextArea } from './index';

import * as styles from './Input.module.scss';

export type TextControlProps = Omit<ControlProps, 'additionalClasses'> &
  InputProps & {
    maxLength?: number;
    multiline?: boolean;
    autoComplete?: string;
    hideCounter?: boolean;
    additionalClasses?: {
      textControl?: string;
      control?: string;
      child?: string;
    };
  };

const TextControl = forwardRef<HTMLInputElement, TextControlProps>(
  (
    {
      onChange,
      type = 'text',
      multiline = false,
      additionalClasses: { textControl: wrapperClass, ...controlClasses } = {},
      autoComplete,
      placeholder,
      disabled,
      errorText,
      maxLength,
      hideCounter,
      noInfo = false,
      // comes from <Select> .. just discard here since we won't need it, and it doesn't belong on html dom
      // @ts-ignore
      defaultInputComponent,
      ...props
    },
    ref
  ) => {
    const [length, setLength] = useState<number>(0);

    const showCounter = !hideCounter && typeof maxLength === 'number' && maxLength >= 0;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLength(e.target.value.length);
      onChange?.(e);
    };

    const renderChild = () => {
      switch (type) {
        default:
          return multiline ? (
            <TextArea ref={ref as RefObject<HTMLTextAreaElement>} placeholder={placeholder} maxLength={maxLength} />
          ) : (
            <Input
              ref={ref}
              type={type}
              placeholder={placeholder}
              autoComplete={autoComplete}
              maxLength={maxLength}
            />
          );
      }
    };

    return (
      <div
        className={c(styles.textControl, wrapperClass, disabled && styles.disabled, showCounter && styles.showCounter)}
      >
        <Control
          {...props}
          additionalClasses={{ info: styles.info, ...controlClasses }}
          errorText={errorText}
          onChange={handleChange}
          noInfo={noInfo}
          disabled={disabled}
        >
          {renderChild()}
        </Control>

        {!noInfo && showCounter && (
          <div className={styles.charCounter}>
            {length}/{maxLength}
          </div>
        )}
      </div>
    );
  }
);

TextControl.displayName = 'TextControl';

export default TextControl;
