import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Posts from 'components/Posts';
import { graphql } from 'gatsby';

const NewsPage: React.FC = () => {
  return (
    <Layout id="news">
      <SEO title="News" />
      <Posts />
    </Layout>
  );
};

export default NewsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
