import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import Link from 'gatsby-link';
import { motion } from 'framer-motion';

import Container from 'components/ui/Container';
import * as styles from './styles.module.scss';

import * as Styled from './styles';
import { useI18next } from 'gatsby-plugin-react-i18next';
import HorizontalTitleSeparator from '../HorizontalTitleSeparator/HorizontalTitleSeparator';
import TextControl from '../TextField/TextControl';
import useDebounce from '../../utils/useDebounce';
import getCompareString from '../../utils/getCompareString';
import CopyText from '../CopyText';
import { useDispatch } from 'react-redux';
import { selectFilter, setNewsFilter } from '../../infrastructure/news';
import { useAppSelector } from '../../infrastructure/store';

function getSuggestionTitle(name: string, term: string) {
  const termIndex = getCompareString(name).indexOf(term);
  const termLength = term.length;

  if (termIndex === -1) {
    return name;
  }

  return (
    <>
      {name.substring(0, termIndex)}
      <span className={styles.highlight}>{name.substring(termIndex, termIndex + termLength)}</span>
      {name.substring(termIndex + termLength)}
    </>
  );
}

interface Post {
  node: {
    id: string;
    html: string;
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      description: string;
      date: string;
      tags: string[];
      cover: {
        childImageSharp: {
          fluid: FluidObject;
        };
      };
    };
  };
}

const Posts: React.FC = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "news" }, published: { eq: true } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              contenttitle
              description
              date(formatString: "MMM DD, YYYY")
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts: Post[] = allMarkdownRemark.edges;
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const { t } = useI18next();
  const dispatch = useDispatch();
  const newsFilter = useAppSelector(selectFilter);

  const debouncedSearchTerm = useDebounce<string>(newsFilter || '', 300);
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setNewsFilter(e.target.value || null));
  }, []);

  const onFilter = useCallback(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      const compareAgainst = getCompareString(debouncedSearchTerm);

      const newFilteredPosts = posts.filter(post => {
        const { title, description, tags, date } = post.node.frontmatter;
        const { html } = post.node;

        return (
          getCompareString(title).includes(compareAgainst) ||
          getCompareString(description).includes(compareAgainst) ||
          tags.find(tag => getCompareString(tag).includes(compareAgainst)) ||
          getCompareString(date).includes(compareAgainst) ||
          getCompareString(html).includes(compareAgainst)
        );
      });

      setFilteredPosts(newFilteredPosts);
    } else {
      setFilteredPosts(posts);
    }
  }, [debouncedSearchTerm, posts]);

  useEffect(() => {
    onFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, onFilter]);

  const compareAgainst = getCompareString(debouncedSearchTerm);

  return (
    <Container section>
      <HorizontalTitleSeparator title={t('news')} additionalClass="my-16" />
      <div className={styles.search}>
        <TextControl
          additionalClasses={{ textControl: styles.input }}
          value={newsFilter || ''}
          size="m"
          label={t('search')}
          type="text"
          placeholder={t('searchPlaceholder')}
          autoComplete="off"
          onChange={onChange}
        />
        {!!debouncedSearchTerm && debouncedSearchTerm.length > 2 ? (
          <div className={styles.results}>
            <CopyText variant="copy-2">
              {t('filteredPostResults')
                .replace('{original}', `${posts.length}`)
                .replace('{count}', `${filteredPosts.length}`)}
            </CopyText>
          </div>
        ) : null}
      </div>
      <Styled.Posts>
        {filteredPosts.map(item => {
          const {
            id,
            fields: { slug },
            frontmatter: { title, cover, description, date, tags },
          } = item.node;

          return (
            <Styled.Post key={id}>
              <Link to={slug}>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
                  <Styled.Card>
                    <Styled.Image>
                      <Img fluid={cover.childImageSharp.fluid} alt="News Post Image" style={{ maxHeight: '300px' }} />
                    </Styled.Image>
                    <Styled.Content>
                      <Styled.Date>{compareAgainst ? getSuggestionTitle(date, compareAgainst) : date}</Styled.Date>
                      <Styled.Title>{compareAgainst ? getSuggestionTitle(title, compareAgainst) : title}</Styled.Title>
                      <Styled.Description>
                        {compareAgainst ? getSuggestionTitle(description, compareAgainst) : description}
                      </Styled.Description>
                    </Styled.Content>
                    <Styled.Tags>
                      {tags.map(item => (
                        <Styled.Tag key={item}>
                          {compareAgainst ? getSuggestionTitle(item, compareAgainst) : item}
                        </Styled.Tag>
                      ))}
                    </Styled.Tags>
                  </Styled.Card>
                </motion.div>
              </Link>
            </Styled.Post>
          );
        })}
      </Styled.Posts>
    </Container>
  );
};

export default Posts;
