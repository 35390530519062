import React, { forwardRef, RefObject } from 'react';
import c from 'classnames';
import { InputType } from './types';
import { ControlChildProps } from '../Control/Control';

import * as styles from './Input.module.scss';

export type InputProps = ControlChildProps & {
  autoFocus?: boolean;
  type?: InputType;
  autoComplete?: string;
  placeholder?: string;
  maxLength?: number;
  onAction?: () => void;
  additionalClass?: string; // @deprecated - use additionalClasses.root
  additionalClasses?: {
    root?: string;
    input?: string;
  };
  readOnly?: boolean;
  value?: string;
  ref?: RefObject<HTMLInputElement>;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { type = 'text', size = 'm', onAction, disabled, additionalClass, additionalClasses = {}, value = '', ...otherProps },
  ref
) {
  return (
    <div
      className={c(
        styles.input,
        styles[type],
        {
          [styles.disabled]: disabled,
          [styles.large]: size === 'l',
          [styles.medium]: size === 'm',
        },
        additionalClasses.root ?? additionalClass
      )}
    >
      <input
        ref={ref}
        type={type}
        disabled={disabled}
        value={value}
        {...otherProps}
        className={additionalClasses.input}
      />
    </div>
  );
});
