// extracted by mini-css-extract-plugin
export var actionIcon = "Input-module--actionIcon--RUR9r";
export var charCounter = "Input-module--charCounter--b3faF";
export var copy2 = "Input-module--copy-2--FG4nt";
export var copy4 = "Input-module--copy-4--9JDHo";
export var copy6 = "Input-module--copy-6--phjWU";
export var disabled = "Input-module--disabled--jUXZK";
export var hasActionIcon = "Input-module--hasActionIcon--TKC7x";
export var hasIcon = "Input-module--hasIcon--EgWz6";
export var icon = "Input-module--icon--t1d75";
export var info = "Input-module--info--SUkUN";
export var input = "Input-module--input--RA0kJ";
export var large = "Input-module--large--RaBIv";
export var medium = "Input-module--medium--dqy7+";
export var showCounter = "Input-module--showCounter--xcI45";
export var textControl = "Input-module--textControl--NVdAG";
export var textarea = "Input-module--textarea--rKV0Z";
export var toggle = "Input-module--toggle--yI-kY";
export var toggleInput = "Input-module--toggleInput--bMMJt";
export var toggleText = "Input-module--toggleText--N0+Gy";