import React, { Children, cloneElement, PropsWithChildren, ReactElement, ReactNode } from 'react';
import c from 'classnames';

import * as styles from './Control.module.scss';

export type ControlChildProps<Value = string, OnChange = (e: React.ChangeEvent<HTMLInputElement>) => void> = {
  id?: string;
  name?: string;
  value?: Value;
  defaultValue?: Value;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: OnChange;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent) => void;
  size?: 'm' | 'l';
  additionalClasses?: {
    root?: string;
    input?: string;
  };
};

export type ControlProps<
  Value = string,
  OnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
> = ControlChildProps<Value, OnChange> & {
  label?: string | ReactNode;
  errorText?: string;
  variant?: 'outlined' | 'stealth';
  additionalClasses?: {
    control?: string;
    child?: string;
    info?: string;
  };
  noInfo?: boolean;
  inline?: boolean;
};

function Control<Value = string, OnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void>({
  id,
  name,
  disabled,
  label,
  noInfo = false,
  inline = false,
  errorText = '',
  variant = 'outlined',
  additionalClasses = {},
  children,
  ...childProps
}: PropsWithChildren<ControlProps<Value, OnChange>>) {
  const hasError = !!errorText;

  return (
    <div
      className={c(
        styles.control,
        styles[variant],
        hasError && styles.hasError,
        disabled && styles.disabled,
        inline && styles.inline,
        additionalClasses.control
      )}
    >
      {label && <label htmlFor={id ?? name}>{label}</label>}
      <div className={styles.body}>
        {cloneElement(Children.only(children) as ReactElement, {
          id: id ?? name,
          name,
          disabled,
          additionalClasses: {
            root: additionalClasses.child,
            input: styles.focusable,
          },
          ...childProps,
        })}

        {!noInfo && (
          <div className={c(styles.info, additionalClasses.info)}>
            {errorText && <div className={styles.error}>{errorText}</div>}
          </div>
        )}
      </div>
    </div>
  );
}

export default Control;
